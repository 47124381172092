import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { SliceZone } from 'components/Shared';
import { getRelevantNode } from '../utils/helpers';

const Page = ({ data, location }) => {
  const pageLang = 'en-us';
  const altLangs = [{ lang: 'de-de', uid: '' }];

  const { body, meta_title, meta_description, meta_image } = getRelevantNode(
    data.landingPages,
    pageLang
  ).data;

  return (
    <Layout
      pageLang={pageLang}
      altLangs={altLangs}
      seo={{
        title: meta_title,
        description: meta_description,
        image: meta_image?.url,
      }}
      location={location}
    >
      <SliceZone
        allSlices={body}
        recentPosts={data.recentPosts}
        language="en-us"
      />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  {
    recentPosts: allPrismicBlogPost(
      sort: { fields: first_publication_date, order: DESC }
      limit: 3
      filter: { lang: { eq: "en-us" } }
    ) {
      nodes {
        lang
        alternate_languages {
          lang
          uid
        }
        url
        data {
          title {
            text
          }
          subtitle {
            text
          }
          featured_image {
            alt
            url
          }
        }
        uid
        first_publication_date
      }
    }
    landingPages: allPrismicLandingPage {
      nodes {
        lang
        tags
        data {
          meta_title
          meta_description
          meta_image {
            url
          }
          body {
            ... on PrismicLandingPageBodyIntro {
              id
              slice_type
              primary {
                button_text {
                  text
                }
                image {
                  alt
                  url
                }
                title {
                  text
                }
                background_video {
                  embed_url
                  thumbnail_url
                }
              }
            }
            ... on PrismicLandingPageBodyWhySeedtrace {
              id
              slice_type
              primary {
                description {
                  text
                }
                title {
                  text
                }
              }
              items {
                description {
                  text
                }
                image {
                  alt
                  url
                }
                title {
                  text
                }
              }
            }
            ... on PrismicLandingPageBodyBrands {
              id
              slice_type
              items {
                title {
                  text
                }
                location {
                  text
                }
                image {
                  alt
                  url
                }
                link {
                  url
                  link_type
                  target
                }
              }
              primary {
                title {
                  text
                }
              }
            }
            ... on PrismicLandingPageBodyNamedSlice {
              slice_type
              id
              items {
                image {
                  url
                  alt
                }
              }
              primary {
                button_text {
                  text
                }
                button_text_demo {
                  text
                }
                open_hubspot_dialog
                button_text_secondary {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                highlight_newline
                has_iced_background
                has_narrow_text_column
                image_configuration
                has_small_padding
                invert_order
                navigation_link {
                  url
                }
                navigation_link_secondary {
                  url
                }
                slice_title {
                  text
                }
                text {
                  text
                }
                video_link {
                  url
                }
              }
            }
            ... on PrismicLandingPageBodyDarkDivider {
              id
              primary {
                headline_post_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                text {
                  html
                }
                button_text {
                  text
                }
                button_text_demo {
                  text
                }
                button_text_secondary {
                  text
                }
                button_text_newsletter {
                  text
                }
                navigation_link {
                  url
                }
                navigation_link_secondary {
                  url
                }
                image_avatar {
                  url
                  alt
                }
                avatar_name {
                  text
                }
                avatar_title {
                  text
                }
              }
              slice_type
            }
            ... on PrismicLandingPageBodyCaseStudyTeaser {
              id
              slice_type
              primary {
                headline_pre_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                text {
                  html
                  text
                }
                case_study_image {
                  url
                  alt
                }
                testimonial_quote {
                  document {
                    ... on PrismicTestimonalQuote {
                      id
                      data {
                        person_image {
                          url
                          alt
                        }
                        quote {
                          text
                        }
                        person_name {
                          text
                        }
                        company_position {
                          text
                        }
                      }
                    }
                  }
                }
                button_text {
                  text
                }
                navigation_link {
                  url
                }
              }
            }
            ... on PrismicLandingPageBodyAskUsAnything {
              id
              slice_type
              primary {
                contact_form {
                  document {
                    ... on PrismicContactForm {
                      id
                      data {
                        button_text {
                          text
                        }
                        company_placeholder {
                          text
                        }
                        email_placeholder {
                          text
                        }
                        headline_highlight {
                          text
                        }
                        headline_post_highlight {
                          text
                        }
                        headline_pre_highlight {
                          text
                        }
                        message_placeholder {
                          text
                        }
                        name_placeholder {
                          text
                        }
                        text {
                          text
                        }
                        success_message {
                          text
                        }
                        error_message {
                          text
                        }
                        privacy_notice {
                          text
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicLandingPageBodyNewsSlice {
              id
              slice_type
              primary {
                slice_title {
                  text
                }
              }
            }
            ... on PrismicLandingPageBodyProductSliderWidget {
              id
              slice_type
              primary {
                api_key {
                  text
                }
              }
            }
            ... on PrismicLandingPageBodyStandaloneItems {
              slice_type
              primary {
                render_on_homepage
              }
              items {
                item {
                  document {
                    ... on PrismicTestimonalQuote {
                      id
                      type
                      data {
                        company_position {
                          text
                        }
                        person_image {
                          url
                          alt
                        }
                        person_name {
                          text
                        }
                        quote {
                          text
                        }
                      }
                    }
                    ... on PrismicNumericFact {
                      id
                      type
                      data {
                        description {
                          text
                        }
                        headline {
                          text
                        }
                        number
                        unit {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicLandingPageBodyLogoBanner {
              slice_type
              items {
                company_logo {
                  url
                  alt
                }
              }
            }
            ... on PrismicLandingPageBodyFourItems {
              slice_type
              primary {
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
              }
              items {
                title {
                  text
                }
                description {
                  text
                }
                link {
                  url
                }
                image {
                  alt
                  url
                }
                link_title {
                  text
                }
              }
            }
            ... on PrismicLandingPageBodyProductSliderWidget {
              id
              slice_type
              primary {
                api_key {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                text {
                  text
                  html
                }
                white_background
              }
            }
            ... on PrismicLandingPageBodyTitleAndDescription {
              id
              slice_type
              primary {
                description {
                  text
                }
                title {
                  text
                  html
                }
                has_small_padding
              }
            }
          }
        }
      }
    }
  }
`;
